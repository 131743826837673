module.exports = {
	BLANK_TOKEN: `[BLANK]`,

	LEARN_CATEGORIES: {
		SPOT_IT: `SPOT_IT`,
		CHECK_IT: `CHECK_IT`,
		STOP_IT: `STOP_IT`,
	},

	LABELS: {
		DURING: `DURING`,
		MEDIA: `MEDIA`,
		VOTING: `VOTING`,
		AFTER: `AFTER`,
	},

	COLORS: {
		ELECTING: "WHITE",
		MEDIA: `YELLOW`,
		AFTER: `BLUE`,
		DURING: `PINK`,
		VOTING: `GREEN`,
	},

	NAV: {
		INDEX: ``,
		PLEDGE: `PLEDGE`,
		QUIZ: `QUIZ`,
		PLEDGE_WALL: `PLEDGE_WALL`,
		MISSION: `MISSION`,
		SETTINGS: `SETTINGS`,
	},

	QUESTION_TYPES: {
		MULTIPLE_CHOICE: `MULTIPLE_CHOICE`,
		IMAGE_CHOICE: `IMAGE_CHOICE`,
		TRUE_FALSE: `TRUE_FALSE`,
		FILL_BLANK: `FILL_BLANK`,
	},

	COVER_TYPES: {
		COLOR: `COLOR`,
		VIDEO: `VIDEO`,
		OVERFLOW: `OVERFLOW`,
	},

	LEARN_LINKS : {
		LINK: "LINK",
		INFO: "INFO"
	},

	META_TYPES : {
		SITE: "SITE",
		QUIZ: "QUIZ",
		PLEDGE: "PLEDGE"
	}
};
