import GLOBAL_ENUMS from "../../../enums";

import tw, { css } from "twin.macro";

import pinkCardURL from "../assets/img/Pink Card.png";
import greenCardURL from "../assets/img/Green Card.png";
import yellowCardURL from "../assets/img/Yellow Card.png";
import blueCardURL from "../assets/img/Blue Card.png";
import redCardURL from "../assets/img/Red Card.png";

export const BASE_HEIGHT = 540;
export const BASE_WIDTH = 330;
export const ASPECT_RATIO = BASE_HEIGHT / BASE_WIDTH;

// IMPORTANT: make sure these stay in sync with in tailwind.config.js
// this file has to be an ESModule, and the tailwind file has to be CommonJS, and Gatsby won't let them mix
export const CARD_MARGIN = 32;
export const CARD_SLIVER = 24;
export const BUTTON_RADIUS = 18;
export const CARD_RADIUS = 32;

export const EXPAND_SCALE = 7;

export const COLORS = {
	PINK: {
		tagline: tw`text-cardPink-text-tagline`,
		question: tw`text-cardPink-text-question`,
		colorCard: [
			tw`bg-cardPink-bg-card text-cardPink-text-question`,
			css`
				background-image: url("${pinkCardURL}");
			`,
		],
		imageQuestionCard: tw`bg-white bg-none text-black`,
		overflowCard: tw`bg-white text-black`,
		smallAnswers: tw`bg-cardPink-bg-littleAnswer text-cardPink-text-littleAnswer hover:opacity-75`,
		imageAnswers: tw`bg-transparent text-white`,
		largeAnswers: tw`bg-cardPink-bg-bigAnswer text-cardPink-text-bigAnswer hover:opacity-75`,
		cardButton: tw`bg-cardPink-bg-button text-cardPink-text-button hover:opacity-75`,
		dropdownBody: tw`bg-cardPink-bg-dropdown`,
		dropdownIcon: tw`text-cardPink-icon-dropdown`,
		dropdownSelectedOption: tw`bg-transparent text-cardPink-text-dropdown-selected`,
		dropdownOptions: tw`bg-transparent hover:bg-cardPink-bg-dropdown-options-hover text-cardPink-text-dropdown-options`,

		bg: tw`bg-cardPink`,
		button: tw`
		bg-pink-button-light hover:bg-pink-button-dark focus:bg-pink-button-dark hover:bg-opacity-75
		text-black hover:text-white focus:text-white
		`,
		dropdown: tw`bg-pink-button-light text-pink`,
		text: tw`text-pink`,
		image: pinkCardURL,
	},

	BLUE: {
		tagline: tw`text-cardBlue-text-tagline`,
		question: tw`text-cardBlue-text-question`,
		colorCard: [
			tw`bg-cardBlue-bg-card text-cardBlue-text-question`,
			css`
				background-image: url("${blueCardURL}");
			`,
		],
		imageQuestionCard: tw`bg-white bg-none text-black`,
		overflowCard: tw`bg-white text-black`,
		smallAnswers: tw`bg-cardBlue-bg-littleAnswer text-cardBlue-text-littleAnswer hover:opacity-75`,
		imageAnswers: tw`bg-transparent text-white`,
		largeAnswers: tw`bg-cardBlue-bg-bigAnswer text-cardBlue-text-bigAnswer hover:opacity-75`,
		cardButton: tw`bg-cardBlue-bg-button text-cardBlue-text-button hover:opacity-75`,
		dropdownBody: tw`bg-cardBlue-bg-dropdown`,
		dropdownIcon: tw`text-cardBlue-icon-dropdown`,
		dropdownSelectedOption: tw`bg-transparent text-cardBlue-text-dropdown-selected`,
		dropdownOptions: tw`bg-transparent hover:bg-cardBlue-bg-dropdown-options-hover text-cardBlue-text-dropdown-options`,

		bg: tw`bg-cardBlue`,
		button: tw`
		bg-blue-button-light hover:bg-blue-button-dark focus:bg-blue-button-dark hover:bg-opacity-75
		text-black
		`,
		dropdown: tw`bg-blue-button-light text-blue`,
		text: tw`text-blue`,
		image: blueCardURL,
	},

	GREEN: {
		tagline: tw`text-cardGreen-text-tagline`,
		question: tw`text-cardGreen-text-question`,
		colorCard: [
			tw`bg-cardGreen-bg-card text-cardGreen-text-question`,
			css`
				background-image: url("${greenCardURL}");
			`,
		],
		imageQuestionCard: tw`bg-white bg-none text-black`,
		overflowCard: tw`bg-white text-black`,
		smallAnswers: tw`bg-cardGreen-bg-littleAnswer text-cardGreen-text-littleAnswer hover:opacity-75`,
		imageAnswers: tw`bg-transparent text-white`,
		largeAnswers: tw`bg-cardGreen-bg-bigAnswer text-cardGreen-text-bigAnswer hover:opacity-75`,
		cardButton: tw`bg-cardGreen-bg-button text-cardGreen-text-button hover:opacity-75`,
		dropdownBody: tw`bg-cardGreen-bg-dropdown`,
		dropdownIcon: tw`text-cardGreen-icon-dropdown`,
		dropdownSelectedOption: tw`bg-transparent text-cardGreen-text-dropdown-selected`,
		dropdownOptions: tw`bg-transparent hover:bg-cardGreen-bg-dropdown-options-hover text-cardGreen-text-dropdown-options`,

		bg: tw`bg-cardGreen`,
		button: tw`
		bg-green-button-light hover:bg-green-button-dark focus:bg-green-button-dark hover:bg-opacity-75
		text-black
		`,
		dropdown: tw`bg-green-button-light text-green`,
		text: tw`text-green`,
		image: greenCardURL,
	},

	YELLOW: {
		tagline: tw`text-cardYellow-text-tagline`,
		question: tw`text-cardYellow-text-question`,
		colorCard: [
			tw`bg-cardYellow-bg-card text-cardYellow-text-question`,
			css`
				background-image: url("${yellowCardURL}");
			`,
		],
		imageQuestionCard: tw`bg-white bg-none text-black`,
		overflowCard: tw`bg-white text-black`,
		smallAnswers: tw`bg-cardYellow-bg-littleAnswer text-cardYellow-text-littleAnswer hover:opacity-75`,
		imageAnswers: tw`bg-transparent text-white`,
		largeAnswers: tw`bg-cardYellow-bg-bigAnswer text-cardYellow-text-bigAnswer hover:opacity-75`,
		cardButton: tw`bg-cardYellow-bg-button text-cardYellow-text-button hover:opacity-75`,
		dropdownBody: tw`bg-cardYellow-bg-dropdown`,
		dropdownIcon: tw`text-cardYellow-icon-dropdown`,
		dropdownSelectedOption: tw`bg-transparent text-cardYellow-text-dropdown-selected`,
		dropdownOptions: tw`bg-transparent hover:bg-cardYellow-bg-dropdown-options-hover text-cardYellow-text-dropdown-options`,

		bg: tw`bg-cardYellow`,
		button: tw`
		bg-yellow-button-light hover:bg-yellow-button-dark focus:bg-yellow-button-dark hover:bg-opacity-75
		text-black
		`,
		dropdown: tw`bg-yellow-button-light text-yellow`,
		text: tw`text-yellow`,
		image: yellowCardURL,
	},

	WHITE: {
		tagline: tw`text-white`,

		text: tw`text-white`,
	},

	BLACK: {
		cardButton: tw`bg-black text-white hover:opacity-75`,
	},

	// PROJECT: {
	// 	bg: tw`bg-project`,
	// 	button: tw`
	// 	bg-project-lighter hover:bg-project-light focus:bg-project-light
	// 	text-white hover:text-white focus:text-white
	// 	`,
	// 	dropdown: tw`bg-project-lighter text-white`,
	// 	text: tw`text-project`,
	// 	image: redCardURL,
	// },
};

export const ENUMS = { ...GLOBAL_ENUMS };
