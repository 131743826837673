import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { graphql } from "gatsby";

// ========= UTILS =========
import { useQueryParam, StringParam } from "use-query-params";

// ========= COMPONENTS =========
import PageBackground from "../components/Elements/PageBackground";

// ========= ASSETS =========
import { ENUMS, COLORS } from "../libs/globals";
import logoURL from "../assets/img/brand/PenLogo.png";
import copy from "../assets/copy";

// ========= CUSTOM COMPONENTS =========
const Wrapper = ({ children }) => (
	<div
		css={[
			tw`
				absolute
				w-full h-full px-32
				flex justify-center items-center flex-col
				text-white
				overflow-hidden
				font-centra
			`,
		]}
	>
		{children}
	</div>
);

// ========= MAIN =========
const SocialQuestion = ({ data }) => {
	// ========= QUESTION =========
	const [question, setQuestion] = useState();

	// ========= SLUGS =========
	// look for a q query
	const [slug] = useQueryParam(`q`, StringParam);

	// when the query changes, change the current question
	useEffect(() => {
		setQuestion(
			data.allSanityQuizQuestion.nodes.find(
				(q) => q.slug.current === slug
			)
		);
	}, [slug]);

	// render nothing until the question is grabbed
	if (!question) return <></>;

	// ========= RENDER =========
	return (
		<>
			<PageBackground />
			<Wrapper>
				<div
					css={[
						tw`flex justify-between mb-4`,
						css`
							width: 900px;
						`,
					]}
				>
					<div tw="w-1/2 py-2">
						<h1
							css={[
								tw`
									font-bold uppercase 
									text-white
									text-2xl
									leading-none
								`,
							]}
						>
							{copy.labels.expect}
						</h1>
						<h2
							css={[
								tw`
									italic
									text-2xl
								`,
								COLORS[ENUMS.COLORS[question.label]]
									.tagline,
							]}
						>
							{copy.labels.when[question.label].text}
						</h2>
					</div>
					<div
						css={[
							tw`relative h-full w-1/6`,
							css`
								background-image: url("${logoURL}");
								background-position: right;
								background-size: contain;
								background-repeat: no-repeat;
							`,
						]}
					/>
				</div>

				<h1
					css={[
						tw`font-bold text-5xl mb-6`,
						css`
							width: 900px;
						`,
					]}
				>
					{question.question}
				</h1>

				<div
					css={[
						tw`
							self-start
							text-center text-white font-bold
							rounded-full inline-block cursor-pointer
							pt-1 pb-2 px-16 text-4xl
							ml-6
						`,
						COLORS[ENUMS.COLORS[question.label]].bg,
					]}
				>
					Answer
				</div>
			</Wrapper>
		</>
	);
};

export default SocialQuestion;

// ========= GRAPHQL QUERY =========
export const query = graphql`
	{
		allSanityQuizQuestion {
			nodes {
				question
				slug {
					current
				}
				label
			}
		}
	}
`;
